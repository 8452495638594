<template>
  <div>
    <b-form
      v-on:submit="submitPass"
      v-on:input="compare(verify)"
      id="passwordform"
    >
      <img src="@/assets/XFarmsLogo.png" id="pwrst-logo" alt="" />

      <h5 class="m-3 p-3">Password Reset </h5>

      <b-form-group class="label" label-for="pass1">
        <password
          class="inputfield"
          id="pass1"
          type="password"
          v-model="verify.password1"
          required
          :toggle="true"
          placeholder="Enter your password: "
        />
      </b-form-group>

      <b-form-group class="label" label-for="pass2">
        <password
          class="inputfield"
          id="pass2"
          type="password"
          v-model="verify.password2"
          required
          :toggle="true"
          placeholder="Re-enter Password:"
        >
        </password>
      </b-form-group>
      <p>
        {{ passMatch }}
      </p>
      <b-button class="submitbtn" type="submit" variant="primary">
        Set Password
      </b-button>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import Password from "vue-password-strength-meter";

export default {
  components: { Password },
  name: "PasswordSet",
  data() {
    return {
      verify: { password1: "", password2: "" },
      passMatch: "",
    };
  },
  methods: {
    submitPass(evt) {
      evt.preventDefault();
      if (this.compare(this.verify)) {
        axios
          .post("/passchg", this.verify)
          .then((response) => {
            console.log(response);
            router.replace("/");
            this.$parent.isloggedIn();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    compare(verify) {
      if (verify.password1 == "") {
        this.passMatch = "";
        return false;
      } else if (verify.password1 === verify.password2) {
        this.passMatch = "Passwords match";

        return true;
      } else {
        this.passMatch = "Passwords does not match";

        return false;
      }
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  max-height: 100%;
  position: absolute;
  background-color: white;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  z-index: 102;
}
#passwordform {
  /* margin: 5% 10% 5% 10%; */
  padding: 5% 10% 5% 10%;
}
/* .inputfield {
  margin: 20px auto;
  width: 40%;
} */
#pwrst-logo{
  width: 30%;
}
.label {
  text-align: right;
}
.submitbtn {
  width: 40%;
  max-width: 400px;
}

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  #pwrst-logo{
    width: 70%;
  }
}
</style>
